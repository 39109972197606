body {
  background-color: #dee3e7;
  color: black;
  font-family: sans-serif;
  margin: 0;
  padding: 0;
  text-align: center;
}

dialog {
  box-shadow: 0 0 6px 0 #444;
  max-width: 600px;
  min-width: 60%;
  padding: 1em;
  text-align: left;
}

dialog.big {
  max-width: 100%;
  width: calc(100vw - 2em);
  height: 100vh;
  border: none;
}

fieldset {
  display: grid;
  grid-template-columns: repeat(auto-fit, 180px);
  grid-auto-rows: 1fr;
  grid-gap: 1em;
  padding: 0;
  text-align: center;
  justify-content: center;
  border: 0;
}

#sidebar-toggle-wrapper {
  display: inline;
}

footer,
footer a {
  color: black;
}

#sidebar-toggler-button {
  top: 1em;
  left: 0.5em;
  height: 2em;
  width: 2em;
  background-color: white;
  text-align: center;
  display: inline-grid;
  place-items: center;
  padding: .2em;
  box-shadow: 0 0 5px 0 #444;
  color: #666;
}

#sidebar-toggler-button:hover,
#sidebar-toggler-button:focus {
  color: black;
  cursor: pointer;
}

nav {
  background-color: white;
}

nav.sidebar {
  position: fixed;
  width: 180px;
  height: 100dvh;
  left: 0;
  top: 0;
  transition: left 0.5s ease;
  box-shadow: 0 0 10px 0 #444;
  z-index: 3;
  display: flex;
  flex-direction: column;
}

#navigation-links {
  padding-top: 4em;
  flex-grow: 1;
}

#supplemental-links {
  flex-grow: 0;
}

input:checked ~ nav.sidebar {
  left: -250px;
}

h1 {
  display: inline;
  font-size: small;
  padding-left: .5em;
}

h1 a {
  color: black;
}

nav ul {
  margin: 0;
  padding: 0;
}

nav ul li {
  list-style: none;
  text-align: left;
  border-bottom: 1px inset black;
}

nav ul li a {
  display: block;
  padding-left: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  user-select: none;
}

h1 a:visited {
  color: black;
}

nav ul li a:hover {
  color: black;
  background-color: #efefef;
  cursor: pointer;
}

nav.topbar {
  background-color: white;
}

nav.topbar ul li {
  display: inline-block;
}

table {
  background-color: white;
  border-collapse: collapse;
  width: 100%;
  box-shadow: 0 0 5px 0 #444;
}

th,
td {
  border: 1px solid #efefef;
  text-align: left;
  padding: 0.6em;
}

th:first-child {
  width: 5%;
}

tr:hover td {
  background-color: beige;
}

legend {
  padding-top: 1em;
  text-align: center;
  width: 100%;
  padding-bottom: 1em;
  font-weight: bold;
}

span.icon {
  display: block;
  font-size: 3em;
}

.action-header {
  display: flex;
  align-items: center;
}

.action-header span.icon,
tr.log-row span.icon {
  display: inline-block;
  padding-right: 0.2em;
  vertical-align: middle;
}

.error {
  background-color: salmon;
}

div.error {
  padding: 1em;
}

.title.temporary-status-message {
  color: gray;
}

div.entity {
  background-color: white;
  box-shadow: 0 0 10px 0 #444;
  display: grid;
  grid-column: auto / span 2;
  grid-row: auto / span 2;
  grid-template-rows: auto min-content;
  grid-template-columns: minmax(min-content, auto);
}

h2 {
  display: inline-block;
  font-size: 1em;
  margin-top: 0;
  flex-grow: 1;
}

div.entity h2 {
  grid-column: 1 / span all;
}

details {
  display: inline-block;
}

details[open] {
  margin-top: 1em;
  display: block;
}

/* General Buttons */

button,
input[type="submit"]
{
  padding: 1em;
  color: black;
  text-align: center;
  border: 1px solid #999;
  background-color: white;
  box-shadow: 0 0 6px 0 #aaa;
  user-select: none;
}

/* Action Buttons */
action-button {
  display: flex;
  flex-direction: column;
}

action-button button {
  flex-grow: 1;
  width: 100%;
  z-index: 2;
  border-radius: .7em;
}

action-button details {
  flex-grow: 1;
}

action-button details[open] {
  margin-top: 0;
}

action-button details summary div {
  display: inline-flex;
}

action-button details summary div span:first-child {
  flex-grow: 1;
}

.action-button-footer {
  text-align: left;
  font-size: smaller;
  overflow: auto;
}

execution-button {
  display: inline-block;
  margin-right: .2em;
  margin-left: .2em;
  margin-top: .2em;
}

execution-button button {
  margin-top: 0.2em;
  margin-bottom: 0.2em;
}

/* Button states */

button:hover,
input[type="submit"]:hover {
  box-shadow: 0 0 10px 0 #666;
  cursor: pointer;
}

button:focus,
input[type="submit"]:focus {
  outline: 1px solid black;
}

button:disabled,
input[type="submit"]:disabled {
  color: gray;
  background-color: #333;
  cursor: not-allowed;
}


/* Button animations */

.action-failed {
  transition: 1s;
  background-color: red;
}

.action-success {
  transition: 1s;
  background-color: limegreen;
}

.action-nonzero-exit {
  animation: kf-action-nonzero-exit 1s;
  transition: 1s;
  background-color: orange;
}

.action-timeout {
  transition: 1s;
  background-color: cyan;
}

.action-blocked {
  transition: 1s;
  background-color: purple;
  color: white;
}

img.logo {
  width: 1em;
  height: 1em;
  vertical-align: middle;
}

main {
  padding: 1em;
}

body.has-sidebar main {
  padding-top: 3em;
}

summary {
  cursor: pointer;
}

form div.wrapper {
  background-color: white;
  text-align: left;
}

label {
  min-width: 20%;
  text-align: right;
  display: inline-block;
  padding-right: 1em;
}

#perma-widget {
  position: absolute;
  top: 1em;
  left: 1em;
  z-index: 9;
}

#perma-widget label {
  display: inline;
}

input {
  padding: 0.6em;
}

input:invalid {
  outline: 2px solid red;
}

form .wrapper span.icon {
  display: inline-block;
  vertical-align: middle;
}

form input[type="submit"]:first-child {
  margin-right: 1em;
}

button[name="cancel"]:hover {
  background-color: salmon;
  color: black;
}

input[name="start"]:hover {
  background-color: #aceaac;
  color: black;
}

span.argument-description {
  margin-left: 1em;
}

p.argument-wrapper {
  display: flex;
}

form div.buttons {
  text-align: right;
}

pre {
  border: 1px solid gray;
  padding: 1em;
  min-height: 1em;
  overflow: auto;
  text-align: left;
}

td.exit-code {
  text-align: center;
}

input.invalid {
  background-color: salmon;
}

#available-version {
  background-color: #aceaac;
  padding: 0.2em;
  border-radius: 1em;
}

span.tag {
  background-color: lightgray;
  border-radius: 0.6em;
  padding: 0.2em;
}

div.toolbar {
  padding: .4em;
  text-align: left;
  background-color: #efefef;
  border: 1px solid #999;
  border-bottom: 0;
  display: flex;
  flex-direction: row;
}

div.toolbar * {
  margin-right: 1em;
}

@media screen and (width <= 600px) {
  fieldset {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  }

  label {
    text-align: left;
    margin-bottom: .6em;
    font-weight: bold;
    min-width: auto;
  }

  p.argument-wrapper {
    flex-direction: column;
  }

  span.argument-description {
    margin-left: 0;
    margin-top: .6em;
  }

  dialog {
    border-left: 0;
    border-right: 0;
    width: 100%;
  }
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #333;
    color: white;
  }

  dialog {
    background-color: #222;
    color: white;
  }

  form div.wrapper {
    background-color: #222;
  }

  button,
  input[type="submit"],
  #sidebar-toggler-button {
    border: 1px solid #666;
    background-color: #222;
    box-shadow: 0 0 6px 0 #444;
    color: white;
  }

  #sidebar-toggler-button:focus,
  #sidebar-toggler-button:hover
  {
    color: white;
  }

  footer,
  footer a {
    color: gray;
  }


  h1 a, h1 a:visited {
    color: white;
  }

  nav {
    background-color: #111;
    color: white;
  }

  nav ul li a:hover {
    background-color: #666;
    color: white;
  }

  button:disabled {
    background-color: black;
  }

  table,
  td,
  th {
    border: 1px solid gray;
  }

  td,
  tr {
    background-color: #222;
    color: white;
  }

  tr:hover td {
    background-color: #666;
  }

  div.toolbar {
    background-color: black;
  }
}
